<template>
	<div class="content-wrap">
		<div class="section-top">
			<h4 class="title">
        <span class="txt"
		><span class="line"></span>说明<span class="line"></span
		></span>
			</h4>
			<p class="para s-14 c-666">
				根据《机动车驾驶培训网络远程理论教学技术规范》要求须提供相关身份证明信息，完成认证。请阅读<a
				href="javascript:;" @click="$router.push('/contract')"
			>《理论培训电子合同》</a
			>
			</p>
			<div class="extra">
				<div class="extra-title s-16 has-line">学习要求：</div>
				<div class="extra-content c-666">
					<div class="line">
						<span class="idx">1</span><span>首次播放不能快进；</span>
					</div>
					<div class="line">
						<span class="idx">2</span>
						<span>每次学习完成后，需进行签退操作，否则学时无效需要重新进行学习。</span>
					</div>
					<div class="line">
						<span class="idx">3</span>
						<span>在学习期间出现以下情形，不应计入有效学时时长，并给予提醒：<br>（1）学习者登录后浏览网页信息，以及在线练习、模拟考试的时长；<br>（2）学习者人像特征不相符或登录验证失败的，其验证提示后的时长；<br>（3）学习者学习过程中，跟教学平台交互时间间隔超过预设值，超出部分的时长。</span>
					</div>
				</div>
			</div>
		</div>
		<div class="section-bottom">
			<!-- <van-button class="btn disable">我已阅读（5s）</van-button> -->
			<van-button
				class="btn"
				:disabled="count > 0 ? true : false"
				type="primary"
				block
				@click="changeStep"
			>我已阅读{{ count ? "(" + count + "s)" : "" }}
			</van-button>
		</div>
	</div>
</template>

<script>
import {checkPayStatus, wxAuth} from '@/api/user'
export default {
	name: 'stepOne',
	data() {
		return {
			step: 0,
			timer: null,
			count: 5,
		}
	},
	mounted() {
		const openid = location.search
		if (this.$store.state.cache.screen.wx && !openid.includes('openId=')) {
			let href = window.location.href.replace('login', 'auth')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		}
		this.timer = setInterval(() => {
			this.count--;
			if (this.count <= 0) {
				clearInterval(this.timer);
			}
		}, 1000);
		if (localStorage.getItem('payUserId') && localStorage.getItem('payOrderId')) {
			this.checkPayStatus(localStorage.getItem('payUserId'), localStorage.getItem('payOrderId'));
		}
 	},
	methods: {
		changeStep(eventOrigin = '') {
			// sessionStorage.removeItem('form')
			// this.step++;
			// this.$emit('changeStep', this.step)

			if (this.$store.state.cache.screen.wx) {
				const openid = location.search
				sessionStorage.setItem('openid', openid.split('=')[1])
				if (eventOrigin === '') {
					sessionStorage.removeItem('form')
				}
				this.step++;
				this.$emit('changeStep', this.step)
			} else {
				sessionStorage.removeItem('form')
				this.step++;
				this.$emit('changeStep', this.step)
			}
		},
		/**
		 * 检测支付状态
		 */
		checkPayStatus(userId, orderId) {
			checkPayStatus({
				studentId: userId,
				outTradeNo: orderId
			}).then(({ payStatus }) => {
				if (typeof payStatus !== "number") {
					payStatus = Number(payStatus)
				}
				if (payStatus === 1) {
					this.$emit('changeStep', 3)
				} else {
					this.checkPayStatus(userId, orderId)
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
	.logout {
			position: absolute;
			left: 20px;
			top: 24px;
		}
.content-wrap {
	.section-top {
		padding: 26px 15px 60px;
		box-shadow: 0px 2px 8px 1px #e1e0df;
		border-radius: 14px;

		.title {
			position: relative;
			font-size: 17px;
			text-align: center;

			.txt {
				position: relative;
			}

			.line {
				position: absolute;
				top: 50%;
				display: inline-block;
				width: 30px;
				height: 2px;
				background: #fca142;
				margin: -1px 0 0;

				&:nth-of-type(1) {
					left: -42px;
				}

				&:nth-of-type(2) {
					right: -42px;
				}
			}
		}

		.para {
			line-height: 1.8;
		}
		.extra {
			margin-top: 40px;

			.extra-title {
				position: relative;
				font-weight: bold;

				&.has-line {
					padding-left: 10px;
				}

				&::before {
					position: absolute;
					content: "";
					width: 4px;
					height: 14px;
					background: linear-gradient(90deg, #fca142, #ff8506);
					border-radius: 4px;
					left: 0;
					top: 50%;
					margin-top: -6px;
				}
			}

			.extra-content {
				margin-top: 15px;

				.line {
					position: relative;
					// line-height: 1.8;
					display: flex;
					align-items: flex-start;
					margin-bottom: 10px;

					.idx {
						display: inline-block;
						width: 16px;
						height: 16px;
						background: #fca142;
						border-radius: 16px;
						margin-right: 8px;
						flex: 0 0 16px;
						text-align: center;
						line-height: 16px;
						color: #fff;
						font-size: 12px;
						margin-top: 2px;
					}
				}
			}
		}
	}

	.section-bottom {
		margin-top: 40px;
	}
}

.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;

	.content-wrap {
		.section-top {
			.title {
				.line {
					background: @colorSecond;
				}
			}

			.extra {
				.extra-title {
					&::before {
						background: linear-gradient(90deg, @colorSecond, @colorMain);
					}
				}

				.extra-content {
					.line {
						.idx {
							background: @colorSecond;
						}
					}
				}
			}
		}
	}
}

.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;

	.content-wrap {
		.section-top {
			.title {
				.line {
					background: @colorSecond;
				}
			}

			.extra {
				.extra-title {
					&::before {
						background: linear-gradient(90deg, @colorSecond, @colorMain);
					}
				}

				.extra-content {
					.line {
						.idx {
							background: @colorSecond;
						}
					}
				}
			}
		}
	}
}
</style>

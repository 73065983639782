<template>
    <div class="page-container">
        <div class="header" @click="tool(dian)">
            <page-header :back="step!=0" @navBack="navBack" :pageTitle="pageTitle" @click="tool(dian)">
<!--                <template v-slot:default>-->
<!--                    <van-icon size="20" name="question-o"/>-->
<!--                </template>-->
				<div class="logout" v-if="showLogout">
					<a href="javascript:;" @click="logout" style="color:white">退出学习</a>
				</div>
            </page-header>
        </div>
        <div class="page-body">
            <div class="content">
                <!-- 步骤 -->
                <div class="step-list">
                    <span v-for="(item,index) in steps" :key="index" :class="['step-item',{'active':index==step},{'done':index<step}]">
                        <span v-if="index!=0" class="icon-item">
                            <van-icon size="12" color="#fff" name="arrow"/>
                        </span>
                        {{ item.name }}
                    </span>
                </div>
                <!-- 内容 -->
                <!-- <keep-alive> -->
                <component @changeStep="setStep" :is="steps[step].value"></component>
                <!-- </keep-alive> -->
                <!-- <step-one></step-one> -->
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../components/page-header.vue';
import StepOne from './stepOne.vue';
import StepTwo from './stepTwo.vue';
import StepThree from './stepThree.vue';
import StepFour from './stepFour.vue';
import Vconsole from 'vconsole';

export default {
    data() {
        return {
            //  currentStepComp: null,
            dian:0,
            step: 0,
            steps: [{
                    // '说明','身份验证','照片确认','完成'
                    name: '说明',
                    value: 'stepOne'
                }, {
                    name: '身份验证',
                    value: 'stepTwo'
                }, {
                    name: '照片确认',
                    value: 'stepThree'
                }, {
                    name: '完成',
                    value: 'stepFour'
                },
            ],
            pageTitle: '身份验证',
            timer: null,
			showLogout: true
        }
    },
    components: {
        PageHeader,
        StepOne,
        StepTwo,
        StepThree,
        StepFour,
    },
    mounted() {
        if (this.$route.query.step) {
            this.step = this.$route.query.step;
        }
		if (sessionStorage.getItem('anhuihefei') === '1') {
			this.showLogout = false;
		}
    },
    methods: {
        setStep(val) {
            this.step = val;
        },
        navBack() {
            if (this.step <= 0) return;
            this.step--;
        },
        tool(dian){
            this.dian = dian+1
            if (this.dian >= 5) {
                new Vconsole();
            }
        },
        logout() {
			// 清除缓存
			sessionStorage.clear();
			localStorage.clear();
			let keys = document.cookie.match(/[^ =;]+(?=)/g);
			if(keys) {
				for(let i = keys.length; i--;)
					document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
			}
			// window.location.href = "/index.html#/login?appNo=" + sessionStorage.getItem('appno');
			this.$router.push({ path: '/login'})
		}
    }
}
</script>

<style lang="less">
.step-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 30px;
    .step-item {
        color: #999;
        font-size: 15px;

        .icon-item {
            margin-right: 10px;

            i {
                border-radius: 20px;
                background: #e2e2e2;
                padding: 2px;
            }
        }

        &.done {
            color: #333;

            .icon-item {
                i {
                    background: #ff8506;
                }
            }
        }

        &.active {
            color: #ff8506;

            .icon-item {
                i {
                    background: #ff8506;
                }
            }
        }

        & + .step-item {
            margin-left: 10px;
        }
    }
}

.header {
    padding-bottom: 14px;
}

.page-body {
    margin-top: 0;

    .content {
        position: relative;
        top: -14px;
        background: #fff;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        padding: 15px;

    }
}

.APP02 {
    @colorMain: #2db1ac;
    @colorSecond: #48d8a0;

    .step-list {
        .step-item {
            &.done {
                .icon-item {
                    i {
                        background: @colorMain;
                    }
                }
            }

            &.active {
                color: @colorMain;

                .icon-item {
                    i {
                        background: @colorMain;
                    }
                }
            }

        }
    }

}

.APP03 {
    @colorMain: #1175fe;
    @colorSecond: #3fadfa;

    .step-list {
        .step-item {
            &.done {
                .icon-item {
                    i {
                        background: @colorMain;
                    }
                }
            }

            &.active {
                color: @colorMain;

                .icon-item {
                    i {
                        background: @colorMain;
                    }
                }
            }

        }
    }

}

</style>

<template>
	<div class="content-wrap">
		<div class="section-top">
			<h4 class="title">
				<span class="txt"><span class="line"></span>照片确认<span class="line"></span></span>
			</h4>
			<div class="picture">
				<p class="desc c-666">人脸验证时以监管平台备案的照片为基准，请先确认该照片是否是本人，以免验证无法通过影响课程学习。 </p>
				<div class="img-wrap">
					<img v-if="avatar" class="img" :src="avatar" alt="头像">
					<div v-else class="upload-wrap">
						<div class="icon">
							<van-icon name="photo-o" size="22"/>
							<span>上传图片</span>
							<input class="img-input" @change="imgChange($event)" type="file" accept="image/*" capture="user">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-bottom">
			<!-- <van-button class="btn disable">我已阅读（5s）</van-button> -->
			<van-button
				v-if="showNotMe"
				class="btn default btn-group"
				round
				type="primary"
				plain
				@click="deny"
			>不是本人
			</van-button>
			<van-button
				class="btn btn-group"
				round
				:loading="uploading"
				type="primary"
				@click="changeStep"
			>是我本人
			</van-button>
		</div>
	</div>
</template>

<script>
// getPayStatus,
import { Dialog } from 'vant';
import {uploadImg, updateImg, getPayStatus, wxConfig, checkPayStatus} from '../../api/user';
import {getToken} from "../../utils/cookie";

export default {
	name: 'stepThree',
	data() {
		return {
			step: 2,
			uploading: false,
			showNotMe: true,
		}
	},
	mounted() {
		if (location.host.startsWith('shuangpu.')) {
			this.showNotMe = false;
		}
	},
	computed: {
		token() {
			return getToken()
		},
		userid() {
			return this.$store.state.user.userid
		},
		avatar: {
			get() {
				return this.$store.state.user.avatar
			},
			set(val) {
				this.$store.commit("set_avatar", val);
			}
		},
		paystatus() { // 支付状态
			return this.$store.state.user.paystatus
		},
	},
	methods: {
		imgChange(e) {
			let file = e.target.files[0];
			// let reader = new FileReader();
			// reader.onLoad = () => {
			//   this.avatar = reader.result;
			// };
			// reader.readAsDataURL(file);
			this.upload(file);
			this.avatar = window.URL.createObjectURL(file)
		},
		upload(file) {
			if (this.avatar) return;

			const _file = file;
			const isLt2M = _file.size / 1024 / 1024 < 20;
			if (!isLt2M) {
				this.showAlert("请上传20M以下图片");
				return false;
			}

			// 通过 FormData 对象上传文件
			let formData = new FormData();
			formData.append("file", _file);
			this.uploading = true;
			uploadImg(formData, getToken()).then((data) => {
				this.avatar = data.url;
				updateImg({
					studentId: this.userid,
					studentPic: data.url
				}).then(() => {
					this.uploading = false;
				})
			}).catch(() => {
				this.uploading = false;
			})
		},
		showAlert(msg) {
			return new Promise((resolve) => {
				this.$dialog.alert({
					message: msg,
					theme: 'round-button',
					confirmButtonColor: '#fca142',
					width: 290,
				}).then(() => {
					// on close
					resolve()
				});
			})
		},
		deny() {
			this.showAlert('监管平台上照片不符合要求会影响您学习课程，请尽快联系所在的驾校处理，以免耽误学习进度!').then(() => {
				//this.avatar = '';
				this.$router.push({path: '/train'});
			})
		},
		changeStep() {
			if (!this.avatar) return;
			// this.step++;
			// this.$emit('changeStep', this.step)
			const _this = this
			if (this.paystatus == 0) {
				const openid = sessionStorage.getItem('openid')
				getPayStatus({
					studentId: this.userid,
					openId: openid
				}).then((data) => {
					// if (_this.$store.state.cache.screen.isIos) {
					// 	this.showAlert('身份验证失败，请询问驾校验证流程')
					// }
					if (_this.$store.state.cache.screen.wx) {
						_this.wxPay(data);
					} else if (_this.$store.state.cache.screen.isAndroid) {
						localStorage.setItem('payOrderId', data.outTradeNo);
						localStorage.setItem('payUserId', this.userid);
						const appNo = sessionStorage.getItem('appno') || 'APP01';
						window.location.replace(data.wxOrderInfo.mwebUrl + '&redirect_url=' + encodeURI('https://h5.xunmeizaixian.com/#/auth/?appNo=' + appNo));
					} else if (_this.$store.state.cache.screen.isIos) {
						localStorage.setItem('payOrderId', data.outTradeNo);
						localStorage.setItem('payUserId', this.userid);
						window.location.replace(data.wxOrderInfo.mwebUrl + '&redirect_url=' + encodeURI('https://h5.xunmeizaixian.com/hfjpxh.html'));
					}
					// this.step++;
					// this.$emit('changeStep', this.step)
				})
				return;
			} else {
				if(this.$store.state.user.enterpriseId == 504){
					Dialog.alert({
						title: '温馨提示',
						message: '培训费缴费成功，请至"乌海市车辆管理所大厅一楼西侧"进行体检及拍照等相关流程。\n另考试费为额外费用，考试前请至交管支队进行咨询办理相关手续。',
					}).then(() => {
						// on close
					});
				}
				this.step++;
				this.$emit('changeStep', this.step)
			}
		},
		wxPay (data) {
			const _this = this
			wxConfig().then(res => {
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: res.appId, // 必填，公众号的唯一标识
					timestamp: res.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.nonceStr, // 必填，生成签名的随机串
					signature: res.signature,// 必填，签名
					jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
				})
			})
			wx.ready(() => {
				wx.chooseWXPay({
					timestamp: data.wxOrderInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
					nonceStr: data.wxOrderInfo.nonceStr, // 支付签名随机串，不长于 32 位
					package: data.wxOrderInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
					signType: data.wxOrderInfo.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
					paySign: data.wxOrderInfo.paySign, // 支付签名
					success: function () {
						// 支付成功后的回调函数
						_this.checkPayStatus(data.outTradeNo)
					},
					fail: function (error) {
						console.log('支付失败：' + error)
					}
				});
			})
		},
		/**
		 * 检测支付状态
		 */
		checkPayStatus(orderId) {
			checkPayStatus({
				studentId: this.userid,
				outTradeNo: orderId
			}).then(({ payStatus }) => {
				if (typeof payStatus !== "number") {
					payStatus = Number(payStatus)
				}
				if (payStatus === 1) {
					this.step++;
					this.$emit('changeStep', this.step)
				} else {
					this.checkPayStatus(orderId)
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.content-wrap {
	.van-cell {
		margin-top: 10px;
		// box-shadow: 0 0 8px 0px rgba(0,0,0,0.15);
		box-shadow: 0px 2px 8px 0px rgba(210, 210, 210, 0.48);
		border-radius: 8px;

		&::after {
			border: none;
		}
	}

	.van-field__left-icon {
		margin-right: 6px;
	}

	.section-top {
		padding: 26px 15px;
		box-shadow: 0px 2px 8px 1px #e1e0df;
		border-radius: 14px;

		.title {
			position: relative;
			font-size: 17px;
			text-align: center;

			.txt {
				position: relative;
			}

			.line {
				position: absolute;
				top: 50%;
				display: inline-block;
				width: 30px;
				height: 2px;
				background: #fca142;
				margin: -1px 0 0;

				&:nth-of-type(1) {
					left: -42px;
				}

				&:nth-of-type(2) {
					right: -42px;
				}
			}
		}

		.picture {
			margin-top: 18px;

			.desc {
				line-height: 1.6;
			}

			.img-wrap {
				display: block;
				max-width: 80%;
				width: 108px;
				height: 138px;
				padding: 2px;
				border: 2px solid #F0F1F6;
				margin: 20px auto;

				.img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				.upload-wrap {
					position: relative;
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					height: 100%;

					.icon {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						span {
							display: inline-block;
							margin-top: 6px;
							font-size: 13px;
							color: #666;
						}

						.img-input {
							position: absolute;
							width: 100%;
							height: 100%;
							opacity: 0;
						}
					}
				}
			}
		}

	}

	.section-bottom {
		margin-top: 40px;
		text-align: center;

		.btn-group {
			width: 40%;
			height: 40px;

			& + .btn-group {
				margin-left: 15px;
			}
		}
	}
}

.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;

	.content-wrap {
		.section-top {
			.title {
				.line {
					background: @colorSecond;
				}
			}
		}
	}
}

.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;

	.content-wrap {
		.section-top {
			.title {
				.line {
					background: @colorSecond;
				}
			}
		}
	}
}
</style>

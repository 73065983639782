<template>
  <div :style="{background: bgColor}" :class="['page-header',{'transparent': transparent}]">
    <div @click="navBack" v-if="back" class="section-left">
      <van-icon size="20" name="arrow-left"/>
    </div>
    <div class="section-center">{{pageTitle}}</div>
    <div class="section-right">
      <slot></slot>
      <!-- <van-icon size="20" name="question-o" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      // default: 'linear-gradient(90deg, #fca142, #ff8506)'
      default: ''
    },
    back: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
    navBack() {
      this.$emit('navBack')
    }
  }
};
</script>

<style lang="less" scoped>
.page-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
  color: #ffffff;
  // background: linear-gradient(90deg, #fca142, #ff8506);
  .section-left {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
  }
  .section-center {
    flex: auto;
    text-align: center;
  }
  .section-right {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
  }
  &.transparent {
    .section-left {
      padding: 4px;
      border-radius: 20px;
      background: rgba(0, 0, 0, .3);
    }
    
  }
}
</style>